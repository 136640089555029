import { useState, useEffect } from 'react';

const useApiStyles = () => {
  const [apiStyles, setApiStyles] = useState(null);

  useEffect(() => {
    // Cargar el archivo empresa.json
    fetch('/panel/data/empresa.json')
      .then(response => response.json())
      .then(data => {
        // Obtener el color del tema desde empresa.json
        const colorTheme = data.color_theme;
        // Cargar el JSON de estilos basado en el color del tema
        fetch(`/panel/data/useApiStyles.json`)
          .then(response => response.json())
          .then(stylesData => {
            // Verificar si el color del tema existe en el JSON de estilos
            if (stylesData[colorTheme]) {
              setApiStyles(stylesData[colorTheme].tailwindClasses);
            } else {
              console.error(`El color del tema "${colorTheme}" no está definido en useApiStyles.json`);
            }
          })
          .catch(error => {
            console.error('Error fetching API styles:', error);
          });
      })
      .catch(error => {
        console.error('Error fetching empresa.json:', error);
      });
  }, []);

  return apiStyles;
};

export default useApiStyles;
