import React, { useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import WhatsappButtonProduct from './WhatsappButtonProduct';
import { GrNext, GrPrevious } from 'react-icons/gr';

const ModalProducto = ({ productos, selectedIndex, cerrarModal, agregarAlCarrito, setSelectedIndex }) => {
    const producto = productos[selectedIndex];

    const handleNextProduct = () => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % productos.length);
    };

    const handlePrevProduct = () => {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + productos.length) % productos.length);
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            cerrarModal();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                handleNextProduct();
            } else if (event.key === 'ArrowLeft') {
                handlePrevProduct();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    if (!producto) return null;

    const precioReferencia = Math.round(producto.precio * 1.25);

    return (
        <div
            className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex justify-center items-center"
            onClick={handleOverlayClick}
        >
            <div className="bg-white rounded-lg p-6 max-w-lg mx-auto overflow-y-auto max-h-full sm:max-h-3/4 md:max-h-5/6 lg:max-h-4/5">
                <div className="flex justify-end">
                    <button className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg" onClick={cerrarModal}>
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <img
                    src={`/panel/productos/${producto.new_filename}`}
                    alt={producto.nombre}
                    className="h-full w-full mt-4 rounded-lg"
                />
                <p className="text-gray-500 mt-2 border-b pb-2 mb-4">{producto.categoria}</p>
                <h2 className="text-xl font-semibold mb-2 leading-snug">{producto.nombre}</h2>
                <p className="text-gray-700 mb-2 leading-normal">{producto.descripcion}</p>
                <div className="flex justify-between items-center mt-4 text-sm border-t border-b py-4">
                    {/* <div className='flex flex-row'>
                        <p className="text-gray-500 line-through">${precioReferencia.toLocaleString()}</p>
                        <p className='text-white bg-red-600 text-center px-2 ml-1 rounded'>-25%</p>
                    </div> */}
                    <p className="text-gray-900 font-bold text-2xl"><span className='pt-0 font-normal text-sm pr-2'>Precio </span>${producto.precio.toLocaleString()}</p>
                </div>
                <div className="flex justify-center items-center space-x-3 py-4">
                    <button
                        onClick={() => agregarAlCarrito(producto)}
                        className="bg-gray-950 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
                    >
                        <div className="flex items-center space-x-4">
                            <FaShoppingCart size={22} />
                            <div>Agregar al carrito</div>
                        </div>
                    </button>
                    <WhatsappButtonProduct
                        nombreProducto={producto.nombre}
                        descripcionProducto={producto.descripcion}
                    />
                </div>
                <div className="flex justify-between items-center mt-4 text-sm">
                    <button onClick={handlePrevProduct} className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg">
                        <GrPrevious size={24} />
                    </button>
                    <button onClick={handleNextProduct} className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg">
                        <GrNext size={24} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalProducto;
