// components/Logo.jsx
import React from 'react';
import PropTypes from 'prop-types';

function Logo({ size, className }) {
  const logoUrl = '/images/logo.png';

  return (
    <div className={`flex justify-center items-center ${className}`}>
      <img
        src={logoUrl}
        alt="Logo de la compañía"
        className="rounded-full shadow shadow-gray-200"
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </div>
  );
}

Logo.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
