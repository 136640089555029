import React, { useState, useEffect, useCallback } from 'react';
import { IoLogoWhatsapp } from "react-icons/io5";
import useCompanyInfo from './useCompanyInfo';
import { SlLocationPin } from "react-icons/sl";

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const Carrito = ({ carrito, setCarritoAbierto, eliminarDelCarrito, actualizarCantidad, nombreUsuario, setNombreUsuario }) => {
    const [direccion, setDireccion] = useState('');
    const [telefono, setTelefono] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [totalArticulos, setTotalArticulos] = useState(0);
    const [totalCosto, setTotalCosto] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [ciudad, setCiudad] = useState('');

    const { telefonoWhatsApp } = useCompanyInfo() || {};

    const companyPhone = telefonoWhatsApp || "Teléfono no disponible";

    useEffect(() => {
        // Calcular el total de artículos y el costo total
        const totalArticulos = carrito.reduce((total, producto) => total + producto.cantidad, 0);
        const totalCosto = carrito.reduce((total, producto) => total + producto.precio * producto.cantidad, 0);

        setTotalArticulos(totalArticulos);
        setTotalCosto(totalCosto);
    }, [carrito]);

    useEffect(() => {
        // Obtener la ubicación del usuario utilizando la API de geolocalización del navegador
        const fetchLocation = async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        try {
                            const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=11d31dc095df4030b7a51273d1f274ed`);
                            const data = await response.json();
                            if (data.results.length > 0) {
                                setCiudad(data.results[0].components.city || 'Ciudad no disponible');
                            } else {
                                setCiudad('Ciudad no disponible');
                            }
                        } catch (error) {
                            console.error('Error al obtener la ciudad:', error);
                            setCiudad('Error al obtener la ciudad');
                        }
                    },
                    (error) => {
                        console.error('Error al obtener la geolocalización:', error);
                        setCiudad('Error al obtener la ciudad');
                    }
                );
            } else {
                setCiudad('Geolocalización no soportada');
            }
        };

        fetchLocation();
    }, []);

    const debouncedSetNombreUsuario = useCallback(debounce(setNombreUsuario, 300), []);
    const debouncedSetDireccion = useCallback(debounce(setDireccion, 300), []);
    const debouncedSetTelefono = useCallback(debounce(setTelefono, 300), []);

    const handleNombreUsuarioChange = (e) => {
        debouncedSetNombreUsuario(e.target.value);
    };

    const handleDireccionChange = (e) => {
        debouncedSetDireccion(e.target.value);
    };

    const handleTelefonoChange = (e) => {
        debouncedSetTelefono(e.target.value);
    };

    const handleObservacionesChange = (e) => {
        setObservaciones(e.target.value);
    };

    const abrirWhatsApp = () => {
        let mensaje = `¡Hola! Soy ${nombreUsuario}. \n\nInformación de contacto\n\nDirección: ${direccion} \nTeléfono: ${telefono} \nCiudad: ${ciudad} \n\nMi pedido es el siguiente:\n`;

        carrito.forEach((producto, index) => {
            mensaje += `${index + 1}. ${producto.nombre} (x ${producto.cantidad}) - $${(producto.precio * producto.cantidad).toLocaleString()}\n`;
        });

        mensaje += `\nTotal de artículos: ${totalArticulos}\n`;
        mensaje += `Total del pedido: $${totalCosto.toLocaleString()}\n`;

        if (observaciones) {
            mensaje += `\nObservaciones: ${observaciones}\n`;
        }

        const telefonoWhatsApp = companyPhone; // Número de teléfono de WhatsApp

        window.open(`https://wa.me/${telefonoWhatsApp}?text=${encodeURIComponent(mensaje)}`, '_blank');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validación de campos obligatorios
        if (!nombreUsuario || !direccion || !telefono) {
            setError('Por favor completa todos los campos obligatorios.');
            return;
        }

        // Validación de que el carrito no esté vacío
        if (carrito.length === 0) {
            setError('El carrito está vacío. Por favor agrega al menos un artículo.');
            return;
        }

        setError(''); // Reset error
        setLoading(true);
        abrirWhatsApp();
        setLoading(false);
    };

    const handleAgregarAlCarrito = () => {
        if (carrito.length > 0) {
            setError(''); // Reset error if items are added
        }
    };

    return (
        <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-lg p-4 overflow-y-auto z-50">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Carrito de Compras</h2>
                <button className="text-gray-500 hover:text-gray-800" onClick={() => setCarritoAbierto(false)}>
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            {carrito.map((producto, index) => (
                <div key={index} className="mb-4 border-b pb-4 text-sm">
                    <h3 className="font-semibold">{producto.nombre}</h3>
                    <div className='flex flex-row space-x-3 pt-3 items-center'>
                        <p className="text-gray-600">${producto.precio.toLocaleString()}</p>
                        <div className="flex items-center">
                            <button
                                onClick={() => {
                                    actualizarCantidad(index, producto.cantidad - 1);
                                    handleAgregarAlCarrito();
                                }}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded-l"
                            >
                                -
                            </button>
                            <p className="px-2">{producto.cantidad}</p>
                            <button
                                onClick={() => {
                                    actualizarCantidad(index, producto.cantidad + 1);
                                    handleAgregarAlCarrito();
                                }}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded-r"
                            >
                                +
                            </button>
                        </div>
                        <button
                            onClick={() => eliminarDelCarrito(index)}
                            className="text-red-600 hover:text-red-800 underline-none"
                        >
                            Eliminar
                        </button>
                    </div>
                </div>
            ))}
            <div className="border-t pt-4 mb-4">
                <p className="text-sm font-semibold">Total de artículos: {totalArticulos}</p>
                <p className="text-sm font-semibold">Total del pedido: ${totalCosto.toLocaleString()}</p>
            </div>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Tu nombre"
                    className="border border-gray-300 px-3 py-2 w-full rounded-md mb-2"
                    onChange={handleNombreUsuarioChange}
                    required
                />
                <input
                    type="text"
                    placeholder="Tu dirección"
                    className="border border-gray-300 px-3 py-2 w-full rounded-md mb-2"
                    onChange={handleDireccionChange}
                    required
                />
                <input
                    type="text"
                    placeholder="Tu teléfono"
                    className="border border-gray-300 px-3 py-2 w-full rounded-md mb-2"
                    onChange={handleTelefonoChange}
                    required
                />
                <textarea
                    placeholder="Observaciones"
                    className="border border-gray-300 px-3 py-2 w-full rounded-md mb-2"
                    value={observaciones}
                    onChange={handleObservacionesChange}
                />
                {ciudad &&
                    <div className='py-2 mb-2 shadow bg-slate-50 flex flex-row items-center justify-center space-x-2 border rounded-lg'>
                        <SlLocationPin size={20}/>
                        <p className="text-center text-gray-500">Ciudad: {ciudad}</p>
                    </div>
                }
                <button
                    type="submit"
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center w-full"
                    disabled={loading}
                >
                    <IoLogoWhatsapp className="w-6 h-6 mr-2" />
                    {loading ? 'Enviando...' : 'Enviar pedido por WhatsApp'}
                </button>
            </form>
        </div>
    );
};

export default Carrito;

