import React, { useEffect, memo } from 'react';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo
import RedesSocialesButton from './RedesSocialesButton';
import PayInfoButton from './PayInfoButton';
import ReservaHomeButton from './ReservaHomeButton';
import Logo from './Logo';
import { MdVerified } from "react-icons/md";
import ContactButtons from './ContactButtons';

const CompanyInfo = () => {
  const companyInfo = useCompanyInfo(); // Utiliza el hook useCompanyInfo para obtener la información de la compañía

  useEffect(() => {
    if (companyInfo) {
      document.title = companyInfo.title; // Actualiza el título del documento con el valor de title
    }
  }, [companyInfo]); // Asegura que se actualice solo cuando companyInfo cambia

  if (!companyInfo) {
    // Si no se pudo cargar la información de la compañía, puedes mostrar un mensaje de carga o manejarlo según tus necesidades
    return <div>Cargando información de la compañía...</div>;
  }

  const {
    name,
    title,
    shortDescription,
    linkPay // Añadimos linkPay a la destructuración
  } = companyInfo;

  return (
    <div className="text-center mt-12 bg-white rounded-xl w-full">
      <div className='grid md:grid-cols-2 gap-4'>
        <div className='px-6 lg:px-32 py-8'>
          {/* Mostrar el logotipo */}
          <div className='flex flex-col justify-center items-center'>
            <Logo size={80} aria-label="Company Logo" /> {/* Aquí se pasa la prop size al componente Logo */}
            <div className='flex flex-row items-center justify-center space-x-2'>
              <h2 className='my-2 text-lg md:text-3xl font-semibold text-gray-800' role="heading" aria-level="2">{title}</h2>
              <MdVerified className='text-blue-600 text-xl md:text-2xl' aria-label="Verified" />
            </div>
          </div>
          <p className="font-semibold text-base md:text-lg pb-8">{name}</p>
          <p className="text-sm md:text-base text-start">{shortDescription}</p>
        </div>
        <div
          className="bg-cover bg-center rounded-r-lg"
          style={{
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/images/splash-screen.jpg)',
          }}
          aria-label="Company Background Image"
        >
          <div className="text-lg md:px-12 flex flex-col items-center justify-center h-full py-8">
            <div>
              <ContactButtons />
            </div>
            <div className="text-center">
              {/* Renderiza el componente de información de pago solo si linkPay está presente */}
              {linkPay && <PayInfoButton />}
            </div>
            <div className="text-center mt-2">
              {/* Renderiza el componente de información de reserva con el enlace de reserva */}
              <ReservaHomeButton />
            </div>
            {/* Redes Sociales */}
            <RedesSocialesButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CompanyInfo);
