import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { GrClose, GrNext, GrPrevious } from 'react-icons/gr';

// Configuración de Modal para la accesibilidad
Modal.setAppElement('#root');

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    // Fetch images from JSON file
    const fetchImages = useCallback(async () => {
        try {
            const response = await fetch('/panel/galeria/galeria.json');
            const data = await response.json();
            setImages(data.imagenes.map(imageName => `/panel/galeria/${imageName}`));
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching images:', error);
            setIsLoading(false);
        }
    }, []);

    // Set interval to fetch images every 15 seconds
    useEffect(() => {
        fetchImages();
        const intervalId = setInterval(fetchImages, 15000);
        return () => clearInterval(intervalId);
    }, [fetchImages]);

    // Handle keydown events for navigation
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedImageIndex !== null) {
                if (event.key === 'ArrowRight') {
                    nextImage();
                } else if (event.key === 'ArrowLeft') {
                    prevImage();
                } else if (event.key === 'Escape') {
                    closeModal();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedImageIndex]);

    // Open image modal
    const openModal = useCallback((index) => {
        setSelectedImageIndex(index);
    }, []);

    // Close image modal
    const closeModal = useCallback(() => {
        setSelectedImageIndex(null);
    }, []);

    // Show next image
    const nextImage = useCallback(() => {
        setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, [images]);

    // Show previous image
    const prevImage = useCallback(() => {
        setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }, [images]);

    if (isLoading) {
        return <div className="text-center py-4">Cargando...</div>;
    }

    if (images.length === 0) {
        return <div className="text-center py-4">No hay imágenes disponibles</div>;
    }

    return (
        <div className="container mx-auto py-12 px-8">
            <div className="pb-12 space-x-3 flex flex-row items-center justify-center">
                <h1 className="text-center font-extrabold text-3xl text-gray-800">Galería</h1>
            </div>

            <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-2 justify-center items-center px-4 lg:px-36">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Galería ${index + 1}`}
                        className="object-cover h-36 w-48 rounded-lg cursor-pointer transition-transform transform hover:scale-105 duration-200 ease-in-out"
                        onClick={() => openModal(index)}
                    />
                ))}
            </div>

            {selectedImageIndex !== null && (
                <Modal
                    isOpen={selectedImageIndex !== null}
                    onRequestClose={closeModal}
                    contentLabel={`Imagen ${selectedImageIndex + 1}`}
                    className="max-w-4xl mx-auto bg-transparent"
                    overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center"
                >
                    <div className='p-2 w-full md:max-w-lg lg:max-w-4xl'>
                        <div className="relative w-full h-full flex justify-center items-center">
                            <img
                                src={images[selectedImageIndex]}
                                alt={`Imagen ${selectedImageIndex + 1}`}
                                className="max-h-full max-w-full rounded-lg object-contain"
                            />
                            <div className="absolute top-0 left-0 right-0 flex justify-between items-center w-full p-4">
                                <button
                                    className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg"
                                    onClick={closeModal}
                                >
                                    <GrClose size={24} />
                                </button>
                                <div className="flex space-x-6">
                                    <button
                                        className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg"
                                        onClick={prevImage}
                                    >
                                        <GrPrevious size={24} />
                                    </button>
                                    <button
                                        className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg"
                                        onClick={nextImage}
                                    >
                                        <GrNext size={24} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Gallery;
