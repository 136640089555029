import React, { useState, useEffect } from 'react';

function VideoGallery() {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        // Obtener el archivo JSON local
        const response = await fetch('/panel/data/idvideos.json');

        if (!response.ok) {
          throw new Error('Error al obtener los videos del archivo JSON');
        }

        const data = await response.json();
        setVideos(data.videos);
      } catch (error) {
        console.error('Error al obtener los videos desde el archivo JSON:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  // Ocultar el componente si no hay videos y no está cargando
  if (videos.length === 0 && !isLoading) {
    return null;
  }

  return (
    <div className="container mx-auto py-12">
      <h2 className="text-4xl font-extrabold mb-8 text-center text-gray-800">Galería de Videos</h2>
      {isLoading ? (
        <p className="text-center text-gray-500">Cargando videos...</p>
      ) : (
        <div className="grid md:grid-cols-2 gap-4">
          {videos.map((videoId, index) => (
            <div key={index} className="w-full aspect-w-16 aspect-h-9">
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title={`Video ${index + 1}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-72 md:h-96 rounded-lg"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default VideoGallery;
