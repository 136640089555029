import React, { useState, useEffect } from 'react';

const GoogleMap = () => {
  const [mapSrc, setMapSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetch('/panel/data/empresa.json');
        if (response.ok) {
          const companyInfoData = await response.json();
          if (companyInfoData && companyInfoData.googleMap) {
            setMapSrc(companyInfoData.googleMap);
          } else {
            throw new Error('La información del mapa no está disponible en el archivo JSON.');
          }
        } else {
          throw new Error('Error al obtener los datos de la empresa');
        }
      } catch (error) {
        console.error('Error al cargar la información del mapa:', error);
        setError('Error al cargar el mapa. Por favor, inténtalo de nuevo más tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyInfo();
  }, []);

  if (isLoading) {
    return <div className="text-center py-4">Cargando...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  return (
    <div className="my-4">
      <iframe
        title="Google Map"
        src={mapSrc}
        width="100%"
        height="400"
        className="border-0"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
