/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import useCompanyInfo from './useCompanyInfo';
import { IoIosClose, IoIosMenu, IoIosSearch } from 'react-icons/io';
import { SlWallet } from "react-icons/sl";
import useApiStyles from './useApiStyles';
import PagosModal from './PagosModal';

function Navigation() {
  const companyInfoData = useCompanyInfo();
  const [menuOpen, setMenuOpen] = useState(false);
  const [videosAvailable, setVideosAvailable] = useState(false);
  const [pagosModalOpen, setPagosModalOpen] = useState(false);
  const themeColors = useApiStyles();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const timestamp = new Date().getTime(); // Generar timestamp
        const response = await fetch(`/panel/data/idvideos.json?timestamp=${timestamp}`);
        const data = await response.json();
        setVideosAvailable(data.videos && data.videos.length > 0);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setVideosAvailable(false);
      }
    };

    fetchVideos();
  }, []);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);
  const openPagosModal = () => {
    setPagosModalOpen(true);
    closeMenu(); // Cerrar el menú cuando se abre el modal de pagos
  };
  const closePagosModal = () => setPagosModalOpen(false);

  const { title } = companyInfoData || {};

  const linkClasses = themeColors 
    ? `${themeColors.text} ${themeColors.hoverBackground} ${themeColors.hoverText} px-3 py-2 rounded-md text-sm font-medium` 
    : 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium';

  const handleLinkClick = () => closeMenu();

  const baseNavigationLinks = [
    { href: '/', label: 'Inicio' },
    { href: '#productos', label: 'Productos' },
    { href: '#galeria', label: 'Galeria' },
    { href: '#contacto', label: 'Contacto' },
    { href: '#productos', icon: <IoIosSearch size={22} />, label: 'Buscar' },
  ];

  const navigationLinks = [...baseNavigationLinks];

  if (videosAvailable) {
    const galeriaIndex = navigationLinks.findIndex(link => link.label === 'Galeria');
    navigationLinks.splice(galeriaIndex + 1, 0, { href: '#videos', label: 'Videos' });
  }

  return (
    <>
      <nav className={`${themeColors ? themeColors.background : 'bg-gray-900'} fixed top-0 w-full z-50 opacity-90`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-12">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Logo size={50} />
              <a href="/" className={`ml-4 ${themeColors ? themeColors.text : 'text-white'} font-medium text-base md:text-xl`}>
                {title}
              </a>
            </div>
            <div className="hidden md:flex flex-grow justify-end items-center space-x-4">
              {navigationLinks.map(({ href, label, icon }) => (
                <a
                  key={href}
                  href={href}
                  className={linkClasses}
                  onClick={handleLinkClick}
                >
                  {icon || label}
                </a>
              ))}
              <button
                className={`flex items-center ${linkClasses}`}
                onClick={openPagosModal}
              >
                <SlWallet size={22} />
                <span className="ml-2">Pagos</span>
              </button>
            </div>
            <div className="flex md:hidden">
              <button
                onClick={toggleMenu}
                className={`text-gray-300 hover:text-white inline-flex items-center justify-center p-2 rounded-md focus:outline-none ${themeColors ? themeColors.text : ''} ${themeColors ? themeColors.hoverText : ''}`}
                aria-label="Menu"
                aria-expanded={menuOpen}
              >
                {menuOpen ? <IoIosClose size={30} /> : <IoIosMenu size={30} />}
              </button>
            </div>
          </div>
        </div>
        {menuOpen && (
          <div className={`${themeColors ? themeColors.background : 'bg-gray-900'} md:hidden`}>
            <div className="flex flex-col border-t border-gray-200">
              {navigationLinks.map(({ href, label, icon }) => (
                <a
                  key={href}
                  href={href}
                  className={`block px-3 py-4 text-base font-medium border-b ${themeColors ? themeColors.text : 'text-white'} ${themeColors ? themeColors.hoverBackground : 'hover:bg-gray-700'} ${themeColors ? themeColors.hoverText : 'hover:text-white'}`}
                  onClick={handleLinkClick}
                >
                  {icon ? <div className="flex items-center"><span className="mr-2">{icon}</span> {label}</div> : label}
                </a>
              ))}
              <button
                className={`block px-3 py-4 text-base font-medium border-b ${themeColors ? themeColors.text : 'text-white'} ${themeColors ? themeColors.hoverBackground : 'hover:bg-gray-700'} ${themeColors ? themeColors.hoverText : 'hover:text-white'}`}
                onClick={openPagosModal}
              >
                <div className="flex items-center">
                  <SlWallet size={22} className="mr-2" />
                  <span>Pagos</span>
                </div>
              </button>
            </div>
          </div>
        )}
      </nav>
      <PagosModal isOpen={pagosModalOpen} onRequestClose={closePagosModal} />
    </>
  );
}

export default Navigation;
