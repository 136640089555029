import React from 'react';
import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useCompanyInfo from './components/useCompanyInfo';

// Importaciones de componentes
import CompanyInfo from './components/CompanyInfo';
import BottomNavigationBar from "./components/BottomNavigationBar";
import FloatingActionButton from './components/FloatingActionButton';
import Footer from './components/Footer';
import GoogleMap from './components/GoogleMap';
import Gallery from './components/Gallery';
import Navigation from './components/Navigation';
import VideoGallery from './components/VideoGallery';
import ProductosList from './components/ProductosList';

function App() {
    const companyInfoData = useCompanyInfo();
    const currentPageUrl = window.location.href;

    // Verifica si los datos de la compañía están cargados
    const title = companyInfoData ? companyInfoData.title : 'Título por defecto';
    const description = companyInfoData ? companyInfoData.shortDescription : 'Descripción por defecto';
    const ogImage = companyInfoData ? companyInfoData.ogImageUrl : 'URL de la imagen por defecto'; // Agrega la URL de la imagen predeterminada
    const fbProfileUrl = companyInfoData ? companyInfoData.fbProfileUrl : 'URL del perfil de la empresa en Facebook';
    const twitterImage = companyInfoData ? companyInfoData.twitterImageUrl : 'URL de la imagen por defecto en Twitter'; // Agrega la URL de la imagen predeterminada para Twitter

    return (
        <HelmetProvider>
            <div className="App">
                {/* Navegación */}
                <Navigation />

                {/* Contenido principal */}
                <header id='inicio' className="App-header bg-gray-100 p-2 md:p-8 flex flex-col items-center justify-center">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        {/* Etiquetas Open Graph para redes sociales */}
                        <meta property="og:title" content={title} />
                        <meta property="og:description" content={description} />
                        <meta property="og:image" content={ogImage} />
                        <meta property="og:url" content={currentPageUrl} />
                        <meta property="og:type" content="website" />

                        {/* Etiquetas para WhatsApp */}
                        <meta property="og:site_name" content={title} />
                        <meta property="article:publisher" content={fbProfileUrl} />
                        <meta property="article:author" content={fbProfileUrl} />

                        {/* Etiquetas para Telegram */}
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:title" content={title} />
                        <meta property="twitter:description" content={description} />
                        <meta property="twitter:image" content={twitterImage} />
                    </Helmet>
                    <div className='w-full mt-0'>
                        <CompanyInfo />
                    </div>

                    <div id='productos' className='w-full bg-white mt-6 rounded-lg'>
                        <ProductosList />
                    </div>

                    <div>
                        <FloatingActionButton />
                    </div>
                </header>

                {/* Sección de contenido adicional */}
                <section>
                    <div id='galeria'>
                        <Gallery />
                    </div>

                    <div id='videos' className='w-full bg-gray-900'>
                        <VideoGallery />
                    </div>

                    <div id='contacto'>
                        <GoogleMap />
                    </div>

                    {/* Barra de navegación inferior */}
                    <div>
                        <BottomNavigationBar />
                    </div>

                    {/* Pie de página */}
                    <Footer />
                </section>
            </div>
        </HelmetProvider>
    );
}

export default App;
