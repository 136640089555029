import { useEffect, useState } from 'react';

const useCompanyInfo = () => {
  const [companyInfoData, setCompanyInfoData] = useState(null);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetch('/panel/data/empresa.json');
        if (response.ok) {
          const data = await response.json();
          setCompanyInfoData(data);
        } else {
          throw new Error('Error al obtener los datos de la compañía');
        }
      } catch (error) {
        console.error('Error al cargar companyInfo desde el archivo JSON:', error);
        // Puedes manejar el error aquí, como mostrar un mensaje al usuario
      }
    };

    fetchCompanyInfo();
  }, []); // Se utiliza un array vacío para que useEffect se ejecute solo una vez al montar el componente

  return companyInfoData;
};

export default useCompanyInfo;
